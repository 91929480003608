import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { window, document } from 'browser-monads';


const getPushById = (pushId) => {
    return document.getElementById(pushId);
};

const removePushById = (pushId) => {
    var push = getPushById(pushId);
    if (push) {
        document.body.removeChild(push);
    }
};

const Push = (props) => {
    const {
        show = true,
        pushId,
        floatingElRef,
        prepend = false
    } = props;

    const handleResize = () => {
        var push = getPushById(pushId);
        if (push && floatingElRef.current) {
            push.style.height = `${floatingElRef.current.clientHeight}px`;
        }
    };

    useEffect(() => {
        // Remove first to avoid unhandled renders
        window.removeEventListener('resize', handleResize);
        if (show) {
            window.addEventListener('resize', handleResize);
        } else {
            removePushById(pushId);
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                if (floatingElRef && floatingElRef.current.clientHeight) {
                    var push = document.createElement('div');
                    push.setAttribute("id", pushId);
                    push.style.width = '100%';
                    push.style.height = `${floatingElRef.current.clientHeight}px`;
                    if (prepend) {
                        document.body.prepend(push);
                    } else {
                        document.body.appendChild(push);
                    }
                }
            });
        }
        return () => { removePushById(pushId) };
    }, [floatingElRef, show]);

    return null;
};

Push.propTypes = {
    /**
     * The push element id
     */
    pushId: PropTypes.string.isRequired,
    /**
     *  If `true`, put the push element on top of the body
     */
    prepend: PropTypes.bool
};

export default Push;
