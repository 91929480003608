// VisibilitySensor.jsx

import React, { Component } from "react";
import PropTypes from "prop-types";
import VSensor from "react-visibility-sensor";

class VisibilitySensor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true
        };
    }

    render() {
        const { active } = this.state;
        const { once, children, ...others } = this.props;
        return (
            <VSensor
                active={active}
                onChange={isVisible =>
                    once &&
                    isVisible &&
                    this.setState({ active: false })
                }
                {...others}
            >
                { typeof children === 'function' ? ({ isVisible }) => children({ isVisible }) : children }
            </VSensor>
        );
    }
}

VisibilitySensor.propTypes = {
    once: PropTypes.bool,
    children: PropTypes.any.isRequired
};

VisibilitySensor.defaultProps = {
    once: true
};

export default VisibilitySensor;