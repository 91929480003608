import React from "react"
import Link from "./Link"
import Text from "./Text"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Parallax from "./Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import FluidImage from "./FluidImage"
import { Box, Chip, useMediaQuery, useTheme } from "@material-ui/core"
import CtaButton from "./CtaButton"

const useStyles = makeStyles(theme => ({
  backgroundParallax: {
    position: "absolute !important",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  gridCardWrapper: {
    padding: theme.spacing(0, 1 / 2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1 / 2, 11, 1 / 2),
      paddingBottom: theme.spacing(1),
      "&:not(:last-child)": {
        padding: theme.spacing(0, 1 / 2, 17, 1 / 2),
        paddingBottom: theme.spacing(1),
      },
    },
  },
  card: {
    border: 0,
    borderRadius: 0,
    position: "relative",
    marginBottom: 40,
    minHeight: 420,
    overflow: "visible",
    "& .logoNarrow": {
      position: "absolute",
      left: 20,
      right: 20,
      top: 20,
      zIndex: 2,
      "& .parallax-inner > div": {
        textAlign: "left",
        maxWidth: "50%",
      },
      "& picture, & img": {
        height: "140px !important",
        width: "auto !important",
      },
      "&.logoNarrowDesktop, &.logoNarrowDesktop img, &.logoNarrowDesktop picture":
        {
          [theme.breakpoints.up("sm")]: {
            maxHeight: "125px !important",
          },
        },
      "&.logoNarrowMobile, &.logoNarrowMobile img, &.logoNarrowMobile picture":
        {
          [theme.breakpoints.down("sm")]: {
            maxHeight: "125px !important",
          },
        },
      "&.logoNarrowCenter, &.logoNarrowCenter img, &.logoNarrowCenter picture":
        {
          "& .parallax-inner": {
            display: "block !important",
          },
          "& .parallax-inner > div": {
            textAlign: "center !important",
            maxWidth: "100%",
          },
        },
    },
    "& .cardMedia": {
      display: "block",
    },
    "& .parallaxFix .parallax-inner > div ": {
      inset: "-50% 0px !important",
    },
    "&.big .cardForeground": {
      width: "50%",
    },
    "& .cardForeground": {
      width: "50%",
      float: "right",
      "& .layer": {
        position: "absolute",
        top: "50%",
        right: 0,
        height: "124%",
        width: "74%",
        maxHeight: 620,
        transform: "translate(0, -50%)",
        [theme.breakpoints.down("sm")]: {
          width: "87%",
          height: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        "& .image": {
          height: "100%",
        },
      },
    },
    "& .cardForeground33": {
      width: "50%",
      float: "right",
      "& .layer": {
        position: "absolute",
        top: "50%",
        right: 0,
        height: "124%",
        width: "74%",
        maxHeight: 620,
        transform: "translate(0, -50%)",
        [theme.breakpoints.down("sm")]: {
          width: "87%",
          height: "100%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        "& .image": {
          height: "100%",
        },
      },
    },
    "&.big .cardContent": {
      width: "33%",
    },
    "& .cardContent": {
      position: "relative",
      padding: 20,
      minHeight: 400,
      width: "50%",
      float: "left",
      display: "flex",
      alignContent: "end",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    "& .cardContent50": {
      position: "absolute",
      width: "50%",
      float: "left",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    "& .textWrapper": {
      width: "100%",
    },
    "& .textWrapper .info": {
      color: "#b2b2b2",
    },
    "& .textWrapper .info > *": {
      display: "inline-block",
      height: "auto !important",
    },
    "& .textWrapper .info div + span": {
      marginLeft: 10,
    },
    "& .textWrapper .title": {
      marginTop: 20,
      color: "#b2b2b2",
    },
    "& .textWrapper .links": {
      marginTop: 20,
      color: "#b2b2b2",
      "& .button": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
    "& .position_foreground": {
      position: "relative",
      right: "10px",
      [theme.breakpoints.up("md")]: {
        right: "10%",
      },
    },
    "& .position_foreground50": {
      position: "relative",
      width: "100%",
    },
  },
  buttonCard: {
    zIndex: "99",
    width: "100%",
    height: "100%",
    background: "transparent",
    WebkitTextFillColor: "transparent",
    position: "absolute",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& img": {
      display: "none",
    },
  },
  ctaButtonWidth: {
    minWidth: "240px",
  },
  badgeTitle: {
    position: "absolute",
    top: 24,
    right: 0,
    minHeight: 37,
    background: "#ffbd08",
    minWidth: 97,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    padding: 10,
    "& p": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 14,
      color: "#1d1d1b",
    },
  },
}))

const handleNarrowLogoClasses = product => {
  let classes = []
  classes.push("logoNarrow")
  if (product?.list_box_v2_narrow_logo_desktop?.field_value) {
    classes.push("logoNarrowDesktop")
  } else if (product?.list_box_v2_narrow_logo_mobile?.field_value) {
    classes.push("logoNarrowMobile")
  }
  if (product?.list_box_v2_position_center_logo?.field_value) {
    classes.push("logoNarrowCenter")
  }
  return classes.join(" ")
}

const Card50 = ({ onClick, children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const valuesListBox = children.list_box_v2
  return (
    <Grid xs={12} md={6} item className={classes.gridCardWrapper}>
      <Card className={clsx(classes.card)}>
        {valuesListBox?.list_box_v2_up_title?.field_value && (
          <Box className={classes.badgeTitle}>
            <Text
              component="div"
              variant="title"
              weight="bold"
              align="left"
              verticalAlign="middle"
            >
              {valuesListBox?.list_box_v2_up_title?.field_value}
            </Text>
          </Box>
        )}

        <div className="flexCard">
          <div className="cardMedia parallaxFix">
            <CtaButton
              className={clsx(classes.buttonCard, "button")}
              {...valuesListBox?.list_box_v2_cta?.field_value}
              next
              size="large"
              trackingAction={
                valuesListBox?.list_box_v2_cta?.field_value?.tracking?.outbound
              }
              trackingLabel={
                "CardProductV2 - " +
                (valuesListBox?.list_box_v2_cta?.field_value?.label ||
                  valuesListBox?.list_box_v2_cta?.field_value?.title)
              }
            >
              {valuesListBox?.list_box_v2_cta?.field_value?.label ||
                valuesListBox?.list_box_v2_cta?.field_value?.title}
            </CtaButton>
            <Parallax
              className={classes.backgroundParallax}
              bgImage={valuesListBox?.list_box_v2_image_half_width?.field_value}
              bgMobileImage={
                valuesListBox?.list_box_v2_image_mobile?.field_value
              }
            ></Parallax>
          </div>
          {valuesListBox?.list_box_v2_image_foreground_50?.field_value && (
            <Box
              align="center"
              className="cardForeground position_foreground50"
            >
              {!isMobile ? (
                <FluidImage
                  {...valuesListBox?.list_box_v2_image_foreground_50
                    ?.field_value}
                />
              ) : (
                <FluidImage
                  {...valuesListBox?.list_box_v2_image_foreground?.field_value}
                />
              )}
            </Box>
          )}

          <CardContent className="cardContent50">
            <div className="textWrapper">
              {valuesListBox?.list_box_v2_pre_title?.field_value ||
                (valuesListBox?.list_box_v2_pre_order?.field_value && (
                  <Box className="info">
                    {valuesListBox?.list_box_v2_pre_title?.field_value && (
                      <Chip
                        label={valuesListBox?.list_box_v2_pre_title?.field_value?.replace(
                          /<\/?[^>]+(>|$)|&nbsp;/g,
                          ""
                        )}
                      />
                    )}
                    {valuesListBox?.list_box_v2_pre_order?.field_value && (
                      <Chip
                        label={valuesListBox?.list_box_v2_pre_order?.field_value?.replace(
                          /<\/?[^>]+(>|$)|&nbsp;/g,
                          ""
                        )}
                      />
                    )}
                  </Box>
                ))}

              {children.title && (
                <Box className="title">
                  <Link
                    {...valuesListBox?.list_box_v2_cta}
                    type={valuesListBox?.list_box_v2_cta?.type}
                    product_code={
                      valuesListBox?.list_box_v2_cta?.field_value?.product_code
                    }
                  >
                    <Text className="specialTitleXL" color="textTertiary">
                      {children?.title}
                    </Text>
                  </Link>
                </Box>
              )}

              {
                <Box className="links">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    {valuesListBox?.list_box_v2_discover_now?.field_value &&
                      !valuesListBox?.list_box_v2_cta?.field_value?.href && (
                        <Grid xs={12} item>
                          <Text
                            variant="bodyM"
                            weight="bold"
                            align="left"
                            verticalAlign="middle"
                          >
                            {
                              valuesListBox?.list_box_v2_discover_now
                                ?.field_value
                            }
                          </Text>
                        </Grid>
                      )}
                    {valuesListBox?.list_box_v2_cta?.field_value?.href && (
                      <Grid xs={12} item>
                        <CtaButton
                          className={clsx(classes.ctaButtonWidth, "button")}
                          {...valuesListBox?.list_box_v2_cta?.field_value}
                          next
                          size="large"
                          trackingAction={
                            valuesListBox?.list_box_v2_cta?.field_value
                              ?.tracking?.outbound
                          }
                          trackingLabel={
                            "CardProductV2 - " +
                            (valuesListBox?.list_box_v2_cta?.field_value
                              ?.label ||
                              valuesListBox?.list_box_v2_cta?.field_value
                                ?.title)
                          }
                        >
                          {valuesListBox?.list_box_v2_cta?.field_value?.label ||
                            valuesListBox?.list_box_v2_cta?.field_value?.title}
                        </CtaButton>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              }
            </div>
          </CardContent>
        </div>
      </Card>
    </Grid>
  )
}

//TO DO CARD 100

const Card100 = ({ onClick, children }) => {
  const classes = useStyles()
  const valuesListBox = children.list_box_v2
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Grid xs={12} md={12} item className={classes.gridCardWrapper}>
      <Card className={clsx(classes.card, isMobile === false ? "big" : null)}>
        {valuesListBox?.list_box_v2_up_title?.field_value && (
          <Box className={classes.badgeTitle}>
            <Text
              component="div"
              variant="title"
              weight="bold"
              align="left"
              verticalAlign="middle"
            >
              {valuesListBox?.list_box_v2_up_title?.field_value}
            </Text>
          </Box>
        )}

        {valuesListBox?.list_box_v2_logo_card_100?.field_value && (
          <div className={handleNarrowLogoClasses(valuesListBox)}>
            <Box>
              <ParallaxItem
                disabled={isMobile}
                styleInner={{
                  position: "relative",
                  display: "flex",
                  alignItems: "start",
                  height: "100%",
                  width: "100%",
                }}
                y={["8", "-8"]}
              >
                <FluidImage
                  {...valuesListBox?.list_box_v2_logo_card_100?.field_value}
                />
              </ParallaxItem>
            </Box>
          </div>
        )}

        <div className="cardMedia parallaxFix">
          <CtaButton
            className={clsx(classes.buttonCard, "button")}
            {...valuesListBox?.list_box_v2_cta?.field_value}
            next
            size="large"
            trackingAction={
              valuesListBox?.list_box_v2_cta?.field_value?.tracking?.outbound
            }
            trackingLabel={
              "CardProductV2 - " +
              (valuesListBox?.list_box_v2_cta?.field_value?.label ||
                valuesListBox?.list_box_v2_cta?.field_value?.title)
            }
          >
            {valuesListBox?.list_box_v2_cta?.field_value?.label ||
              valuesListBox?.list_box_v2_cta?.field_value?.title}
          </CtaButton>
          <Parallax
            className={classes.backgroundParallax}
            bgImage={valuesListBox?.list_box_v2_image_full_width?.field_value}
          ></Parallax>
        </div>

        {valuesListBox?.list_box_v2_image_foreground_100?.field_value && (
          <Box align="center" className="cardForeground position_foreground">
            <FluidImage
              {...valuesListBox?.list_box_v2_image_foreground_100?.field_value}
            />
          </Box>
        )}
        <CardContent className="cardContent">
          <div className="textWrapper">
            {valuesListBox?.list_box_v2_pre_title?.field_value ||
              (valuesListBox?.list_box_v2_pre_order?.field_value && (
                <Box className="info">
                  {valuesListBox?.list_box_v2_pre_title?.field_value && (
                    <Chip
                      label={valuesListBox?.list_box_v2_pre_title?.field_value?.replace(
                        /<\/?[^>]+(>|$)|&nbsp;/g,
                        ""
                      )}
                    />
                  )}
                  {valuesListBox?.list_box_v2_pre_order?.field_value && (
                    <Chip
                      label={valuesListBox?.list_box_v2_pre_order?.field_value?.replace(
                        /<\/?[^>]+(>|$)|&nbsp;/g,
                        ""
                      )}
                    />
                  )}
                </Box>
              ))}

            {children.title && (
              <Box className="title">
                <Link
                  {...valuesListBox?.list_box_v2_cta}
                  type={valuesListBox?.list_box_v2_cta?.type}
                  product_code={
                    valuesListBox?.list_box_v2_cta?.field_value?.product_code
                  }
                >
                  <Text className="specialTitleXL" color="textTertiary">
                    {children?.title}
                  </Text>
                </Link>
              </Box>
            )}

            {
              <Box className="links">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  {valuesListBox?.list_box_v2_discover_now?.field_value &&
                    !valuesListBox?.list_box_v2_cta?.field_value?.href && (
                      <Grid xs={12} item>
                        <Text
                          variant="bodyM"
                          weight="bold"
                          align="left"
                          verticalAlign="middle"
                        >
                          {valuesListBox?.list_box_v2_discover_now?.field_value}
                        </Text>
                      </Grid>
                    )}
                  {valuesListBox?.list_box_v2_cta?.field_value?.href && (
                    <Grid xs={12} item>
                      <CtaButton
                        className={clsx(classes.ctaButtonWidth, "button")}
                        {...valuesListBox?.list_box_v2_cta?.field_value}
                        next
                        size="large"
                        trackingAction={
                          valuesListBox?.list_box_v2_cta?.field_value?.tracking
                            ?.outbound
                        }
                        trackingLabel={
                          "CardProductV2 - " +
                          (valuesListBox?.list_box_v2_cta?.field_value?.label ||
                            valuesListBox?.list_box_v2_cta?.field_value?.title)
                        }
                      >
                        {valuesListBox?.list_box_v2_cta?.field_value?.label ||
                          valuesListBox?.list_box_v2_cta?.field_value?.title}
                      </CtaButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            }
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

const CardProductV2 = ({ onClick, children, type }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return type === "half" || isMobile ? (
    <Card50 onClick={onClick} children={children} />
  ) : (
    <Card100 onClick={onClick} children={children} />
  )
}

export default CardProductV2
