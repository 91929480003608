import { makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  "@keyframes lds-ellipsis1": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },

  "@keyframes lds-ellipsis3": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
  "@keyframes lds-ellipsis2": {
    "0%": {
      transform: "translate(0,0)",
    },
    "100%": {
      transform: "translate(8px, 0)",
    },
  },

  ldsEllipsis: {
    display: "inline-block",
    position: "relative",
    // width: "10px",
    // height: "10px",

    "& div": {
      position: "absolute",
      top: 0,
      width: 3,
      height: 3,
      borderRadius: "50%",
      background: "#fff",
      animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",

      "&:nth-child(1)": {
        left: 2.66,
        animation: "$lds-ellipsis1 0.6s infinite",
      },
      "&:nth-child(2)": {
        left: 2.66,
        animation: "$lds-ellipsis2 0.6s infinite",
      },
      "&:nth-child(3)": {
        left: 10.66,
        animation: "$lds-ellipsis2 0.6s infinite",
      },
      "&:nth-child(4)": {
        left: 18.66,
        animation: "$lds-ellipsis3 0.6s infinite",
      },
    },
  },
}))

const LoadingBullets = () => {
  const classes = useStyles()
  return (
    <div className={classes.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
export default LoadingBullets
