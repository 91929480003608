import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Collapse, Box, Hidden } from "@material-ui/core"
import { window } from "browser-monads"

import Module from "../components/Module"
import Text from "../components/Text"
import SubscribeForm from "../components/SubscribeForm"
import { GTMEvent } from "../utils/gtm"

const useStyles = makeStyles(theme => ({
  newsletterSubscription: {
    marginTop: "50px !important",
  },
  formWrapper: {
    background: theme.customPalette.greyClear,
    padding: theme.spacing(5, 6),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(5, 2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      textAlign: "center",
    },
  },
  subtitle: {
    //fontSize: 24,
    marginBottom: theme.spacing(2),
    //marginTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      // paddingBottom: theme.spacing(5),
    },
  },
  subscribeForm: {},
  disclamer: {
    border: `0px solid ${theme.customPalette.primaryGrey05}`,
    background: theme.customPalette.primaryGrey05,
    color: "#7d7d7d",
  },
  resultWrapper: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    height: 60,
    borderRadius: "4.3px",
    border: "solid 0px #9e78aa",
    width: "100%",
  },
  result: {
    display: "block",
    verticalAlign: "middle",
    textAlign: "center",
    width: "100%",
    color: "black",
  },
  subscribeButton: {
    height: 60,
    width: "100%",
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.medium,
  },
  emailField: {
    width: "100%",
    height: "100%",
  },
  input: {
    ...theme.fonts.faces.titleL,
    "&::placeholder": {
      color: "black",
    },
  },
  outlinedInput: {
    color: "black",
    height: "100%",
    "&$error $notchedOutline": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  focused: {},
  notchedOutline: {},
  error: {},
  privacyInfo: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(6),
    },
    "& p": {
      margin: 0,
    },
  },
  privacyInfoNoSpaces: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
    "& p": {
      margin: 0,
    },
  },
  formWrapperNoSpaces: {
    background: theme.customPalette.greyClear,
    padding: theme.spacing(5, 6),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(0, 2),
    },
  },
}))

const NewsletterSubscriptionModule = props => {
  const {
    title,
    subtitle,
    buttonText,
    messages,
    privacyInfo,
    noSpaces,
    ...others
  } = props
  const classes = useStyles()
  const ref = useRef(null)

  const [showRes, setShowRes] = useState(false)

  const handleResult = res => {
    if (res === "success") {
      GTMEvent(others.repo, "form_newsletter", "success")
      setShowRes(true)
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    } else {
      GTMEvent(others.repo, "form_newsletter", "error")
    }
  }

  useEffect(() => {
    const handleScrollEvent = () => {
      let visible = false
      let bounding = ref?.current?.getBoundingClientRect()

      if (bounding?.top > window?.innerHeight && visible) {
        visible = false
      }
    }

    if (ref && ref?.current) {
      window.addEventListener("scroll", handleScrollEvent)
    }
    return () => {
      window.removeEventListener("scroll", handleScrollEvent, true)
    }
  }, [ref?.current])

  return (
    <Module
      ref={ref}
      className={classes.newsletterSubscription}
      variant="full"
      // newsletterModule={true}
      noSpaces={noSpaces}
      noMargin={false}
    >
      <Container maxWidth="md" id="newsletterModuleId">
        <Box
          className={
            noSpaces ? classes.formWrapperNoSpaces : classes.formWrapper
          }
        >
          <Collapse in={!showRes}>
            <Hidden smDown>
              <Text
                component="p"
                className={classes.title}
                variant="titleXXXL"
                weight="bold"
                overline
                gutterBottom
              >
                {title}
              </Text>
            </Hidden>
            <Hidden mdUp>
              <Text
                component="p"
                className={classes.title}
                variant="titleXXXL"
                weight="bold"
                align="center"
                overline
                gutterBottom
              >
                {title}
              </Text>
            </Hidden>
            {subtitle && (
              <Text
                component="p"
                className={classes.subtitle}
                variant="bodyM"
                weight="medium"
                gutterBottom
              >
                {subtitle}
              </Text>
            )}

            {privacyInfo && (
              <Text
                component="p"
                className={
                  noSpaces ? classes.privacyInfoNoSpaces : classes.privacyInfo
                }
                variant="bodyS"
                weight="medium"
                gutterBottom
              >
                {privacyInfo}
              </Text>
            )}
          </Collapse>
          <SubscribeForm
            noSpaces={noSpaces}
            audience="FH_newsletter"
            classes={{ ...classes }}
            textFieldProps={{
              variant: "filled",
            }}
            flags={{
              newsletter: true,
            }}
            labels={{
              button: buttonText,
              success: messages.success,
              error: messages.failure,
            }}
            onResult={handleResult}
            buttonBelow
            others={others}
            privacyInfo={privacyInfo}
          />
        </Box>
      </Container>
    </Module>
  )
}

export default NewsletterSubscriptionModule
