import * as React from "react"
import { useState, useRef } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import capitalize from "@material-ui/core/utils/capitalize"
import { Container } from "@material-ui/core"
import VisibilitySensor from "@src/animations/VisibilitySensor"
import { showGuides } from "@src/utils/debug"

export const styles = theme => ({
  /* Styles applied to the root element. */
  module: {
    position: "relative",
    border: showGuides("4px solid red"), // FIX ** guides
    margin: "0 !important", // Force reset all
    //padding: '0 !important', // Force reset all
    padding: theme.spacing(4, 4, 4, 4), // FIX **
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0, 3, 0), // FIX **
    },
  },
  noSpaces: {
    marginTop: "0 !important",
    padding: "0 !important",
  },
  variantFull: {
    padding: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "& $container": {
      maxWidth: "100%",
      width: "100%",
      margin: "0 auto",
    },
  },
  variantLeft: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
    "& $container": {},
  },
  variantRight: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
    "& $container": {},
  },
  variantWrap: {
    padding: theme.spacing(12),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4),
    },
    "& $container": {},
  },
  container: {
    border: showGuides("4px solid blue"), // FIX ** guides
    height: "100%",
  },
  noMargin: { padding: 0 },
  marginBottomOnly: {
    padding: 0,
    marginBottom: `${theme.spacing(3)}px !important`,
    [theme.breakpoints.down("sm")]: {
      //marginBottom: '0 !important',
    },
  },
  marginBottomHeroSlider: {
    padding: 0,
    marginBottom: `10vh`,
    [theme.breakpoints.up("sm")]: {
      marginBottom: `10vh`,
      //marginBottom: '0 !important',
    },
  },
  "@keyframes slideUp": {
    "0%": { transform: `translateY(25%)` },
    "100%": { transform: "translateY(0%)" },
  },
  hidden: {
    visibility: "hidden",
    opacity: "0",
  },
  slideIn: {
    visibility: "visible",
    opacity: "0",
    animation: "$slideUp 500ms",
    transition: "opacity 500ms ease-out",
    //animationDelay: "200ms"
    "&::after": {
      opacity: "1",
    },
  },
  visible: {
    visibility: "visible",
    transition: "opacity 200ms ease-out",
    opacity: "1",
  },
  newsletterModule: {
    marginTop: "-32px !important",
    paddingTop: "64px !important",
  },
  imageOverflowModule: {
    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(4)}px !important`,
    }
  }  
})

const Module = React.forwardRef(function Button(props, ref) {
  const {
    classes,
    className,
    style,
    alwaysVisible,
    variant = "initial",
    noMargin,
    marginBottomOnly,
    marginBottomHeroSlider,
    onVisibilityChange,
    newsletterModule,
    imageOverflowModule,
    children,
    noSpaces,
    ...other
  } = props

  const [visible, setVisible] = useState(alwaysVisible)
  const containerRef = useRef(null)

  const onChange = isVisible => {
    if (isVisible && !visible) {
      let css = `visibility: visible; opacity: 1;`
      if (containerRef.current) {
        containerRef.current.classList.add(classes.slideIn)
        containerRef.current.style.cssText = css
      }
      //setVisible(isVisible); // avoid re-render
      if (onVisibilityChange) {
        onVisibilityChange(isVisible)
      }
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        "module",
        noSpaces && classes.noSpaces,
        classes.module,

        {
          [classes.noMargin]: noMargin,
          [classes.marginBottomOnly]: marginBottomOnly,
          [classes.marginBottomHeroSlider]: marginBottomHeroSlider,
          [classes[`variant${capitalize(variant)}`]]: variant !== "initial",
          [classes.imageOverflowModule]: imageOverflowModule,
        },
        newsletterModule && classes.newsletterModule,
        className
      )}
      style={style}
      {...other}
    >
      <Container
        ref={containerRef}
        className={clsx(classes.container, {
          [classes.visible]: visible,
          [classes.hidden]: !visible,
        })}
      >
        {children}
      </Container>
      {!visible && (
        <VisibilitySensor
          partialVisibility
          active={!visible}
          onChange={onChange}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
          ></div>
        </VisibilitySensor>
      )}
    </div>
  )
})

Module.propTypes = {
  /**
   * the module type
   */
  variant: PropTypes.oneOf(["inherit", "full", "left", "right", "wrap"]),
  /**
   * Remove margin
   */
  noMargin: PropTypes.bool,
  /**
   * Apply bottom margin only
   */
  marginBottomOnly: PropTypes.bool,
  /**
   * Apply margin in module with big overflow image
   */
  imageOverflowModule: PropTypes.bool,
  /**
   * Override visibility sensor
   */
  alwaysVisible: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
}

Module.defaultProps = {
  alwaysVisible: false,
}

export default withStyles(styles, { name: "FHModule" })(Module)
