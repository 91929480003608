import * as React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"
import shortid from "shortid"

import FluidImage from "./FluidImage"
import Text from "./Text"
import Push from "./Push"
import CtaButton from "./CtaButton"

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    color: "white",
  },
  /* Styles applied to the icon. */
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  /* TODO: do it better*/
  text: {
    fontSize: "0.9rem !important",
  },
  /* Styles applied to the title. */
  title: {
    marginRight: theme.spacing(1),
  },
  /* Styles applied to the content. */
  content: {},
  /* Styles applied to the cta. */
  link: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "white",
    textDecoration: "underline",
    textDecorationColor: "white",
    "&:hover": {
      color: "white",
    },
  },
  /* Styles applied to the cta. */
  cta: { marginLeft: theme.spacing(1) },
})

const Announcement = React.forwardRef(function Announcement(props, ref) {
  const {
    classes,
    className,
    pushContent = false,
    backgroundColor,
    icon,
    title,
    cta,
    link,
    children,
    ...other
  } = props

  return (
    <>
      <Paper
        ref={ref}
        square
        elevation={-1}
        style={{ background: backgroundColor }}
        className={clsx(classes.root, className)}
        {...other}
      >
        {icon && <FluidImage className={classes.icon} {...icon} />}
        <span>
          {title && (
            <Text
              className={clsx(classes.text, classes.title)}
              component="span"
              weight="bold"
            >
              {title}
            </Text>
          )}
          <Text
            className={clsx(classes.text, classes.content)}
            component="span"
          >
            {children}
          </Text>
          {link && link.title && (
            <a className={clsx(classes.text, classes.link)} {...link}>
              {link.title}
            </a>
          )}
          {cta && (
            <CtaButton
              className={classes.cta}
              variant="contained"
              {...cta}
              next
            >
              {cta.text}
            </CtaButton>
          )}
        </span>
      </Paper>
      {pushContent && (
        <Push
          pushId={`push_announcement_${shortid.generate()}`}
          floatingElRef={ref}
          prepend
        ></Push>
      )}
    </>
  )
})

Announcement.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * the background color of the announcement
   */
  backgroundColor: PropTypes.string,
  /**
   * the icon ot the announcement
   */
  icon: PropTypes.object,
  /**
   * the title of the announcement
   */
  title: PropTypes.string,
  /**
   * the cta ot the announcement
   */
  cta: PropTypes.object,
  /**
   * the link ot the announcement
   */
  link: PropTypes.object,
  /**
   *  If `true`, body push content.
   */
  pushContent: PropTypes.bool,
}

export default withStyles(styles, { name: "FHAnnouncement" })(Announcement)
