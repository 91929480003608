import React from "react"
//import { Parallax as ReactParallax } from 'react-parallax';
import { useTheme } from "@material-ui/core/styles"
import { ParallaxBanner as ReactParallax } from "react-scroll-parallax"
import { Hidden } from "@material-ui/core"
import clsx from "clsx"
import { useMediaQuery } from "@material-ui/core"

const Parallax = ({
  bgImage,
  bgMobileImage,
  amount,
  blur,
  strength,
  className,
  parallaxStyle,
  bannerStyle,
  children,
}) => {
  const bgImageStyle = {
    height: "100%",
    width: "100%",
    //backgroundImage: `url(${bgMobileImage ? bgMobileImage.src : ''})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    /*filter: `blur(${blur}px)`,*/
  }

  const imageMobile = bgMobileImage ? bgMobileImage.src : ""
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      <Hidden smDown>
        {/*<ReactParallax bgImage={bgImage ? bgImage.src : ''} blur={blur} strength={strength} className={className}>*/}
        <ReactParallax
          className={className}
          layers={[
            {
              image: bgImage ? bgImage.src : "",
              amount: typeof amount !== "undefined" ? amount : 0.35,
              props: {
                style: bannerStyle || {
                  backgroundSize: "cover",
                  //top: 0,
                  //bottom: 0,
                  //left: 0,
                  //right: 0
                },
              },
            },
          ]}
          disabled={isMobile}
          style={
            parallaxStyle || {
              height: "100%",
            }
          }
        >
          {children}
        </ReactParallax>
      </Hidden>
      <Hidden mdUp>
        <div
          className={clsx(className, "lazyload")}
          data-bg={imageMobile}
          style={bgImageStyle}
        >
          {children}
        </div>
      </Hidden>
    </>
  )
}

export default Parallax
