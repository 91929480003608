import React, { useEffect, useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Hidden } from "@material-ui/core"
import PropTypes from "prop-types"

import Button from "./Button"
import Icon from "./Icon"
import Text from "./Text"
import { window } from "browser-monads"
import { ghost } from "@src/utils/ghost"
import { useTranslation } from "react-i18next"
import LoadingBullets from "./LoadingBullets"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const useStyles = makeStyles(theme => ({
  loginButton: {
    backgroundColor: theme.customPalette.darkishPurple,
    height: 47,
    width: 142,
    boxShadow: "none",
    [theme.breakpoints.only("xs")]: {
      backgroundColor: "transparent",
      height: "auto",
      width: "auto",
    },
    "&:hover": {
      backgroundColor: theme.customPalette.grapePurple,
      [theme.breakpoints.only("xs")]: {
        backgroundColor: "transparent",
      },
    },
  },
  userIcon: {
    width: 24,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginRight: 0,
    },
  },
  countdownElement: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
  },
}))

const LoginButton = ({ color, title, onClick, ...others }) => {
  const classes = useStyles()
  const { i18n, t } = useTranslation()
  const [userName, setUserName] = useState("")
  const [isNameLoading, setIsNameLoading] = useState(true)
  const { loginGProxy, newsletter } = useContext(GlobalStateContext)

  useEffect(() => {
    const name =
      t("login.userName", { userName: loginGProxy.userName }).length > 15
        ? t("login.userName", {
            userName: loginGProxy.userName,
          }).slice(0, 15) + "..."
        : t("login.userName", { userName: loginGProxy.userName })

    setUserName(name)
  }, [loginGProxy.userName])

  return (
    <Button className={classes.loginButton} onClick={onClick}>
      <Hidden smUp implementation="css">
        <Icon
          className={classes.userIcon}
          name={`${color === "white" ? "user_white" : "user"}`}
        />
      </Hidden>
      <Hidden xsDown implementation="css">
        <Icon className={classes.userIcon} name="user_white" />
      </Hidden>
      <Hidden xsDown implementation="css">
        {/* {!loginGProxy.userName.length > 1 ? (
          <LoadingBullets></LoadingBullets>
        ) : (
          <Text variant="bodyS">
            {loginGProxy.userName.length > 1 ? userName : title}
          </Text>
        )} */}
        <Text variant="bodyS">
          {loginGProxy.userName.length > 1 ? userName : title}
        </Text>
      </Hidden>
    </Button>
  )
}

LoginButton.propTypes = {
  /**
   * The color of the component.
   */
  color: PropTypes.oneOf(["white", "black"]),
}

LoginButton.defaultProps = {
  color: "white",
}

export default LoginButton
