import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import loading from '../assets/img/loading/loading.gif';
import FluidImage from './FluidImage';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    img: {
        width: 96,
        height: 96
    }
}));

const Progress = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FluidImage className={classes.img} src={loading} alt="" />
        </div>
    );
};

export default Progress;