import React, { Fragment, useState, useEffect, useRef, useContext } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import AppBarUI from "@material-ui/core/AppBar"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Link from "@material-ui/core/Link"
import Toolbar from "@material-ui/core/Toolbar"
import Chip from "@material-ui/core/Chip"
import { window, document } from "browser-monads"
import axios from "axios"

import { EqualHeight, EqualHeightElement } from "../components/EqualHeight"
import Announcement from "../components/Announcement"
import Text from "../components/Text"
import Icon from "../components/Icon"
import LoginButton from "../components/LoginButton"
import Logo from "../components/Logo"
import ChooseCountryOverlay from "../components/ChooseCountryOverlay"
import { GTMEvent } from "../utils/gtm"
import { detectAlpha2Country } from "@src/utils/country"
import cookie from "@src/utils/cookie"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { useTranslation } from "react-i18next"
import { Modal } from "@material-ui/core"

import NewsletterSubscriptionModule from "@src/modules/NewsletterSubscriptionModule.js"

// Lib
import { Helmet } from "react-helmet"
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core"
import Button from "../components/Button"

import newspopDesktop from "./../assets/img/Fanhome_Popup_Newsletter_DESKTOP.png"
import newspopMobile from "./../assets/img/Fanhome_Popup_Newsletter_MOBILE.png"

import "../style/HeaderModule.css"
import CardProductV2 from "../components/CardProductv2"
//import { getKeys } from "@src/utils/dictionary"

const useStyles = makeStyles(theme => ({
  differentCountry: {
    width: "100%",
    backgroundColor: theme.customPalette.darkishPurple,
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    padding: 8,
    color: "#ffffff",
    zIndex: "1001",
    position: "relative",
    "& br": {
      display: "none",
      [theme.breakpoints.only("xs")]: {
        display: "flex",
      },
    },
  },
  appBar: {
    padding: theme.spacing(1, 2, 1, 2),
    backgroundColor: "transparent",
    color: "#000",
    boxShadow: "none",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(3, 2, 3, 2),
    },
    top: 0,
    zIndex: 1000,
  },
  appBarTop: {
    marginTop: 30,
    [theme.breakpoints.only("sm")]: {
      marginTop: 35,
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: 50,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    position: "relative",
    "& button": {
      width: "auto !important",
      height: "auto !important",
      boxShadow: "unset !important",
    },
  },
  toolbarMarginTop: {
    marginTop: 36,
  },

  appBarShift: {
    //  width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    // marginRight: drawerWidth,
  },
  announcement: {
    position: "relative",
    width: "100%",
    zIndex: 1001,
  },
  announcementSpacer: {},
  title: {
    flexGrow: 1,
  },
  appBarButton: {
    background: "inherit",
    fontWeight: "bold",
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: 3,
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      background: "inherit",
    },
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    display: "inline-flex",
    flexGrow: 1,
    margin: 0,
    width: "100%",
    maxWidth: "200px",
    height: 100,
    "& a": {
      height: 100,
      maxWidth: "200px",
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: "184px",
      height: 43,
      "& a": {
        height: 43,
        maxWidth: "184px",
      },
    },
  },
  logoDesktop: {
    display: "inline-flex",
    position: "relative",
    flexGrow: 1,
    margin: 0,
  },
  icon: {
    height: 32,
  },
  sticky: {
    position: "fixed",
    //top: '0',
    zIndex: 1000,
    background: "white",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    outline: "3px solid rgba(0,0,0,0.03)",
    "& $announcementSpacer": {
      display: "none",
    },
  },
  stickyBlack: {
    position: "fixed",
    //top: '0',
    zIndex: 1000,
    background: "#1f1f1f",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    outline: "3px solid rgba(0,0,0,0.03)",
    "& $announcementSpacer": {
      display: "none",
    },
    "& $menuCategory": {
      color: "#fff",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "100%",
    // backgroundColor: '#662482'
  },
  drawerHeader: {
    borderTopColor: "#ffffff",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
    padding: 0,
    margin: "40px 80px 0",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    minHeight: "auto",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.only("xs")]: {
      margin: "20px 16px",
      justifyContent: "space-between",
      paddingRight: 0,
    },
    "& $logo": {
      position: "relative",
      left: "unset",
      transform: "unset",
      maxWidth: "260px",
      "& a": {
        maxWidth: "260px",
        backgroundPosition: "left",
      },
    },
    "& $closeIcon": {
      height: 16,
    },
  },
  menuTitle: {
    borderTop: `1px solid ${theme.customPalette.greyClear}`,
    paddingTop: 10,
    marginTop: 15,
    color: "#000",
  },
  closeIcon: {
    height: 48,
    [theme.breakpoints.only("xs")]: {
      height: 32,
    },
  },
  closeButton: {
    padding: 0,
    width: "auto !important",
    height: "auto !important",
    boxShadow: "unset !important",
  },
  list: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 80px",
    },
  },
  listItem: {
    justifyContent: "flex-start",
    margin: 0,
    padding: theme.spacing(4, 0),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(3, 2),
    },
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  pageLink: {
    color: "#000",
    lineHeight: 1,
    textDecoration: "none",
    "&:visited": {
      color: "#000",
    },
    "&:hover": {
      color: theme.customPalette.tangerine,
      textDecoration: "none",
    },
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    textAlign: "left",
    alignItems: "center",
    fontFamily: "Spezia",
    lineHeight: 1.17,
    fontWeight: "400",
    fontSize: 24,
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
    },
  },
  categoryLink: {
    color: "#000",
    lineHeight: 1,
    textDecoration: "none",
    "&:visited": {
      color: "#000",
    },
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    textAlign: "left",
    alignItems: "center",
    fontFamily: "Spezia",
    lineHeight: 1.17,
    fontWeight: "400",
    fontSize: 24,
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
    },
  },
  chip: {
    marginBottom: theme.spacing(1),
  },
  goIcon: {
    height: 32,
  },
  desktopRightSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "uppercase",
  },
  menuCategory: {
    marginRight: theme.spacing(3),
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },
  closeIconUser: {
    width: 20,
    height: 20,
  },
  drawerUserArea: {
    width: "100vw",
    [theme.breakpoints.up("md")]: {
      width: "25vw",
    },
  },
  userMenuHeader: {
    borderBottom: "1px solid rgba(0,0,0, .1)",
  },
  loginText: {
    color: "#662482",
    borderBottom: "2px solid #662482",
  },
  input: {
    ...theme.fonts.faces.titleL,
    paddingLeft: 16,
    "&::placeholder": {
      color: "#000",
    },
  },
  outlinedInput: {
    color: "#000000",
    minHeight: "48px",
    background: "#f8f8f8",
    "&$focused $notchedOutline": {
      borderColor: `white !important`,
    },
    "&$error $notchedOutline": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  emailField: {
    width: "100%",
  },
  showPassIcon: {
    width: 20,
    height: 20,
  },
  inputPass: {
    width: "100%",
    "& input": {
      padding: "0px 16px",
      minHeight: "48px",
      "&::placeholder": {
        color: "#000",
      },
    },
    "& .MuiFormLabel-root": {
      color: "#000",
      fontFamily: "Spezia",
      fontSize: 14,
    },
  },
  buttonUserArea: {
    width: "100%",
    height: "48px",
  },
  arrowRight: {
    width: 20,
    height: 20,
    marginLeft: 16,
  },
  iconDefault: {
    width: 24,
    height: 24,
  },
  socialBox: {
    backgroundColor: "#f2f2f2",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    gap: "16px",
  },
  forgotPWDButton: {
    color: "#000",
    borderBottom: "2px solid #f29100",
    textTransform: "uppercase",
  },
  newsletterPopupContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: " column",
    justifyContent: "center",
  },
  newsPopContent: {
    backgroundColor: "#f0f0f0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    position: "relative",
    overflow: "scroll",

    width: "100vw",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      width: "80vw",
      height: "auto",
      justifyContent: "center",
    },
  },
  newspopMobile: {
    display: "block",
    objectFit: "contain",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  newspopDesktop: {
    display: "none",
    objectFit: "contain",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  iconClosePop: {
    position: "absolute",
    top: 10,
    right: 10,
    width: 16,
    height: 16,
  },
  divider: {
    position: "relative",
    padding: "32px 0px",
    "& span": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      display: "block",
      width: 100,
      textAlign: "center",
    },
  },
  containerCategory: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "16px",
  },
  iconSearch: {
    width: 24,
    marginRight: 16,
    cursor: "pointer",
  },
  iconSearchMobile: {
    position: "relative",
    left: "14vh",
    cursor: "pointer",
    width: 24,
  },
  bgGrey: {
    backgroundColor: "#fbfbfb",
  },
  search_section_mobile: {
    position: "absolute",
    opacity: "0",
    backgroundColor: "#fff",
    zIndex: -1,
    display: "flex",
    alignItems: "center",
  },
  search_section_mobile_active: {
    left: "0",
    marginLeft: "-16px",
    width: "calc(100% + 32px)",
    opacity: "1",
    visibility: "visible",
    display: "flex",
    position: "absolute",
    height: "100%",
    top: "-8px",
    backgroundColor: "#fff",
    zIndex: "2",
    alignItems: "center",
    transition: "all 0.45s ease-in",
  },
  search_input: {
    fontFamily: "Poppins",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "20px",
    boxShadow: "none",
    outline: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
}))

const HeaderModule = props => {
  const {
    menuTitle,
    pages,
    myAreaLink,
    repo,
    announcement,
    headerFormInLine,
    templateLabel,
  } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [differentCountry, setDifferentCountry] = useState(false)
  const [allCountries, setAllCountries] = useState([])
  const [showOverlay, setShowOverlay] = useState(false)
  const barRef = useRef(null)
  const topbarRef = useRef(null)
  const differentCountryRef = useRef(null)
  const navbarRef = useRef(null)
  const dispatch = useContext(GlobalDispatchContext)
  const { i18n, t } = useTranslation()
  const [error, setError] = useState(null)
  // Newsletter popup consents gather
  const [modalIsOpen, setIsOpen] = useState(false)
  const [news, setNews] = useState({})

  const [isUserAreaOpened, toogleUserArea] = useState(false)

  const [userName, setUserName] = useState("")
  const { loginGProxy, newsletter } = useContext(GlobalStateContext)

  // New IPstack var
  const [actualCountry, setActualCountry] = useState("")
  const [showBannerChangheCountry, setBannerCountry] = useState(false)

  const [searchMobileIsOpen, setSearchMobile] = useState(false)

  const [values, setValues] = useState({
    email: "",
    password: "",
    expired: false,
    weightRange: "",
    showPassword: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleDrawerOpen = () => {
    GTMEvent("header", "menu_mobile", "open")
    setOpen(true)
  }

  const handleDrawerClose = () => {
    GTMEvent("header", "menu_mobile", "close")
    setOpen(false)
  }

  const handleScroll = e => {
    if (!e) {
      e = {
        target: document,
      }
    }
    const threshold = 50
    const start = threshold * 2
    const friction = 0.3
    if (barRef.current) {
      //let needSticky = e.target.documentElement.scrollTop > threshold;
      let needSticky =
        window.scrollY > threshold /** FIX - support old browser iOS <= 12.1 */
      if (needSticky && !sticky) {
        setSticky(needSticky)
        //let pos = Math.min(-start + (e.target.documentElement.scrollTop * friction), 0);
        let pos = Math.min(-start + window.scrollY * friction, 0)
        barRef.current.style.transform = `translate3d(0,${pos}px,0)`
      }
      if (!needSticky && sticky) {
        setSticky(needSticky)
        barRef.current.style.transform = `translate3d(0,0,0)`
      }
    }
  }

  const [openItemsMenu, setOpenItemsMenu] = useState([])

  const [text, setText] = useState("")
  const [openSearch, setOpenSearch] = useState(false)
  const [serverProducts, setServerProducts] = useState([])
  const [products, setProducts] = useState([])
  const [tags, setTags] = useState([])
  const [brands, setBrands] = useState([])
  const [tagSelected, setTagSelected] = useState("")
  const [wrapper, setWrapper] = useState(0)

  const handleItemsMenuClick = index => {
    setOpenItemsMenu(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index)
      } else {
        return [...prev, index]
      }
    })
  }

  //search method
  // Search method
  const filterServerProducts = (text, country) => {
    if (text.length >= 3) {
      setOpenSearch(true)
      setTagSelected("")
      /*if (cancelTokenSource) cancelTokenSource.cancel();
        cancelTokenSource = axios.CancelToken.source();*/
      axios
        .get(
          `${process.env.GATSBY_SEARCH_PRODUCTS}?${
            text ? `q=${text}` : ""
          }&country=${country}`
        )
        .then(res => {
          const filteredProducts = Object.values(res.data.data?.products || {})
          setServerProducts(filteredProducts)
          setProducts(filteredProducts)
          setBrands(res.data.data?.brands)
          setTags(res.data.data?.tags || [])
        })
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) {
            console.error("Request Error", thrown)
          }
        })
    } else {
      setServerProducts([])
      setProducts([])
      setBrands([])
      setTags([])
      setOpenSearch(false)
    }
  }

  useEffect(() => {
    if (repo.sticky) {
      setTimeout(() => {
        // Remove first to avoid unhandled renders
        window.removeEventListener("scroll", () => handleScroll)
        window.addEventListener("scroll", handleScroll)
      }, 100) // detach from initial render to improve page speed
      // Do it just once to get initial transform
      handleScroll(false)
    }
    if (openSearch) {
      document.body.style.overflow = "hidden"
    }
    return () => (document.body.style.overflow = "unset")
  })

  // New IP stack
  useEffect(() => {
    let websiteCountry =
      detectAlpha2Country().toUpperCase() === "UK"
        ? "GB"
        : detectAlpha2Country().toUpperCase()

    cookie.setRealCookie(
      "cookie_country",
      JSON.stringify({
        countryCode: websiteCountry,
        countryName: "",
      }),
      30
    )

    axios
      .get(process.env.GATSBY_API_GIPSTACK)
      .then(res => {
        const countryCode = res.data.country_code
        const countryName = res.data.country
        setActualCountry(countryName)
        if (countryCode !== websiteCountry) {
          setBannerCountry(true)
        }
      })
      .catch(err => console.log("ip stack error", err))
  }, [])

  useEffect(async () => {
    var resultCountries
    await axios
      .get(`${process.env.GATSBY_COUNTRIES_URL}`)
      .then(res => {
        resultCountries = res?.data?.data
        setAllCountries(resultCountries)
      })
      .catch(err => console.log("all countries get error", err))
  }, [])

  useEffect(() => {
    let diff = 0
    let topbar = 0
    let navbar = 0
    let total = 0

    if (
      differentCountryRef.current &&
      typeof differentCountryRef.current.clientHeight !== "undefined"
    ) {
      diff = differentCountryRef.current.clientHeight
    }
    if (
      topbarRef.current &&
      typeof topbarRef.current.clientHeight !== "undefined"
    ) {
      topbar = topbarRef.current.clientHeight
    }

    if (navbarRef.current) {
      navbar = navbarRef.current.clientHeight
    }

    if (barRef.current) {
      total = barRef.current.clientHeight
    }

    dispatch({
      type: "getHeaderInfo",
      payload: {
        differentCountry: diff,
        topbar: topbar,
        navbar: navbar,
        total: total,
      },
    })
  }, [differentCountryRef.current, topbarRef.current, navbarRef.current])

  const onSubmitLogin = e => {
    e.preventDefault()
    const email = values.email
    const password = values.password
    const expired = values.expired

    window.gproxy.accounts.login({
      callback: onLogin,
      loginID: email,
      password: password,
      sessionExpiration: expired ? -2 : 0,
    })
  }

  const onLogin = response => {
    if (response.errorCode === 0) {
      GTMEvent("login", "click_button", "login_email")
      console.log("LOGGED IN")
      setError(null)
      window.location.href = `/${repo?.seo?.country}/myarea`
    } else {
      console.log("Error on login", response)
      setError(t("login.error"))
    }
  }

  useEffect(() => {
    setUserName(loginGProxy.userName)
  }, [loginGProxy.userName])

  // Newsletter popup consent gather
  useEffect(() => {
    // The pop-up must skip a maximum of once a day and a maximum of 10 times in the 90 days following the first appearance in Homepage
    if (detectAlpha2Country().toUpperCase() === "US") {
      const timer = setTimeout(() => {
        // && !isSubToNewsletter.value
        if (props?.repo?.template_label === "Homepage") {
          if (!cookie.getRealCookie("newsletterPopup")) {
            cookie.setRealCookie(
              "newsletterPopup",
              JSON.stringify({
                howMany: 0,
                perDay: 0,
                date: new Date(),
              }),
              90
            )
          }

          const popUpCookie =
            cookie.getRealCookie("newsletterPopup") != ""
              ? JSON.parse(cookie.getRealCookie("newsletterPopup"))
              : ""
          if (new Date(popUpCookie.date).getDate() !== new Date().getDate()) {
            popUpCookie.howMany += 1
            popUpCookie.perDay = 0
            popUpCookie.date = new Date()
          }
          if (
            popUpCookie.perDay === 0 &&
            new Date().getDate() === new Date(popUpCookie.date).getDate() &&
            popUpCookie.howMany <= 10
          ) {
            GTMEvent(props?.info, "form_newsletter_popup", "view")
            setIsOpen(true)
            popUpCookie.perDay += 1

            cookie.setRealCookie(
              "newsletterPopup",
              JSON.stringify(popUpCookie),
              90
            )
          }
        }
      }, 30000)
      return () => clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const instance = axios.create({
      //timeout: 1000,
      headers: {
        //Authorization: "Bearer " + "huf43yhrevieu43erhvb74vjksdnv",
      },
    })

    // process.env.REACT_APP_ENDPOINT_SETTINGS + "uk"
    instance
      .get(process.env.REACT_APP_ENDPOINT_SETTINGS + detectAlpha2Country())
      .then(response => {
        let news = response?.data?.data?.content[detectAlpha2Country()]
        news = news[0]?.data?.newsletter
        setNews(news)
      })
      .catch(err => console.log("err newsletter", err))
  }, [])

  return (
    <EqualHeight timeout={50} animationSpeed={0}>
      {/* modalIsOpen */}
      <Modal open={modalIsOpen}>
        <Box
          className={classes.newsletterPopupContainer}
          display={"flex"}
          alignItems="center"
        >
          <Box className={classes.newsPopContent}>
            <Icon
              onClick={() => setIsOpen(!modalIsOpen)}
              className={classes.iconClosePop}
              name="close"
            ></Icon>

            <Box p={2}>
              <img
                className={classes.newspopDesktop}
                alt="Newsletter popup desktop version"
                src={newspopDesktop}
              />
              <img
                className={classes.newspopMobile}
                alt="Newsletter popup mobile version"
                src={newspopMobile}
              />
            </Box>
            <Box>
              <NewsletterSubscriptionModule
                title={news?.repository?.title?.field_value}
                subtitle={news?.repository?.subtitle?.field_value}
                messages={{
                  success: news?.repository?.message_success?.field_value,
                  error: news?.repository?.message_error?.field_value,
                }}
                buttonText={news?.repository?.button_text?.field_value}
                privacyInfo={
                  news?.privacy_info ||
                  news?.repository?.privacy_info?.field_value
                }
                noSpaces={true}
              ></NewsletterSubscriptionModule>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Helmet>
        {/* add gbugs script -- Browser Only, not SSR */}
        {props?.templateLabel ? (
          <script>
            {`
        var gbugs = {config: {
          module: '${props?.templateLabel}'
        }}`}
          </script>
        ) : null}
        {props?.templateLabel && (
          <script
            defer="defer"
            type="text/javascript"
            src={`${process.env.GATSBY_GBUGS_URL}`}
          ></script>
        )}
      </Helmet>
      <EqualHeightElement name="announcement">
        {announcement && (
          <Announcement
            ref={topbarRef}
            className={classes.announcement}
            backgroundColor={announcement.backgroundColor}
            icon={announcement.icon}
            title={announcement.title}
            cta={announcement.cta}
            link={announcement.link}
          >
            {announcement.text}
          </Announcement>
        )}
      </EqualHeightElement>
      {showBannerChangheCountry && (
        <div className={classes.differentCountry} ref={differentCountryRef}>
          It seems that you are in {actualCountry}. <br />
          <a href="#" onClick={() => setShowOverlay(true)}>
            Would you like to change country?
          </a>
          {allCountries && (
            <ChooseCountryOverlay
              open={showOverlay}
              onClose={() => setShowOverlay(false)}
              countries={allCountries}
            />
          )}
        </div>
      )}

      <AppBarUI
        position="absolute"
        ref={barRef}
        className={clsx(
          classes.appBar,
          differentCountry && !sticky ? classes.appBarTop : null,
          {
            [repo.black ? classes.stickyBlack : classes.sticky]: sticky,
            [classes.appBarShift]: open,
          }
        )}
      >
        <div className={classes.announcementSpacer}>
          <EqualHeightElement
            timeout={0}
            placeholder
            name="announcement"
          ></EqualHeightElement>
        </div>

        {/* MENU MAIN DESKTOP */}
        <Hidden smDown implementation="css">
          <Toolbar
            className={clsx(
              classes.toolbar,
              showBannerChangheCountry && !sticky
                ? classes.toolbarMarginTop
                : ""
            )}
            ref={navbarRef}
          >
            {pages && !headerFormInLine ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                id="menu-button"
                onClick={handleDrawerOpen}
              >
                <Icon
                  className={classes.icon}
                  name={`${
                    (sticky || repo.dark) && !repo.black
                      ? "menu_purple"
                      : "menu_white"
                  }`}
                />
              </IconButton>
            ) : null}
            {repo.template_label === "Homepage" ? (
              <h1
                className={
                  headerFormInLine ? classes.logoDesktop : classes.logo
                }
              >
                <Logo
                  id="header-logo"
                  dark={(sticky || repo.dark) && !repo.black}
                  templateLabel={templateLabel}
                  onClick={() => GTMEvent("header", "click_link", "Home")}
                />
              </h1>
            ) : (
              <div
                className={
                  headerFormInLine ? classes.logoDesktop : classes.logo
                }
              >
                <Logo
                  id="header-logo"
                  dark={(sticky || repo.dark) && !repo.black}
                  templateLabel={templateLabel}
                  onClick={() => GTMEvent("header", "click_link", "Home")}
                />
              </div>
            )}

            <div className={classes.desktopRightSection}>
              {/* Menu category */}
              {headerFormInLine
                ? headerFormInLine.map((link, index) => {
                    return (
                      <Link key={index} {...link.link}>
                        <Text
                          className={classes.menuCategory}
                          variant="title"
                          weight="bold"
                          color={
                            sticky || repo.dark ? "textPrimary" : "textTertiary"
                          }
                        >
                          {link.link.title}
                        </Text>
                      </Link>
                    )
                  })
                : null}
              <div
                className={`${
                  searchMobileIsOpen
                    ? classes.search_section_mobile_active
                    : classes.search_section_mobile
                }`}
              >
                <div className="container w-100 h-100">
                  <div className="row h-100">
                    <div className="col-24 h-100">
                      <div className="px-2 h-100 d-flex justify-content-between align-items-center gap-4">
                        <Icon
                          className={classes.iconSearch}
                          name="search-white"
                        ></Icon>
                        <form className="form-search-mobile">
                          <input
                            className={classes.search_input}
                            placeholder={t("search.label")}
                            value={text}
                            autoFocus
                            onChange={evt => {
                              setText(evt.target.value)
                              filterServerProducts(
                                evt.target.value,
                                props?.repo?.seo?.country
                              )
                            }}
                          ></input>
                        </form>
                        <Icon
                          className={classes.iconSearch}
                          name="close"
                          onClick={() => {
                            setSearchMobile(false)
                            setOpenSearch(false)
                            setText("")
                          }}
                        ></Icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Icon
                className={classes.iconSearch}
                name={!sticky ? "search-white" : "search-black"}
                id="search-input-desktop"
                onClick={() => {
                  setSearchMobile(true)
                  document
                    .getElementsByClassName("makeStyles-search_input-60")[0]
                    .focus()
                  GTMEvent("Header", "click_button", "search open")
                }}
              ></Icon>
              {myAreaLink && myAreaLink.href ? (
                <LoginButton
                  onClick={() => {
                    window.location.href = myAreaLink.href
                  }}
                  id="login-button"
                  {...myAreaLink}
                  color={`${
                    (sticky || repo.dark) && !repo.black ? "black" : "white"
                  }`}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Toolbar>
        </Hidden>

        {/* MENU MAIN MOBILE */}
        <Hidden mdUp implementation="css">
          <Toolbar
            className={clsx(
              classes.toolbar,
              showBannerChangheCountry && !sticky
                ? classes.toolbarMarginTop
                : ""
            )}
            ref={navbarRef}
          >
            {pages ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                id="menu-button"
                onClick={handleDrawerOpen}
              >
                <Icon
                  className={classes.icon}
                  name={`${
                    (sticky || repo.dark) && !repo.black
                      ? "menu_purple"
                      : "menu_white"
                  }`}
                />
              </IconButton>
            ) : (
              <div></div>
            )}

            {repo.template_label === "Homepage" ? (
              <p className={classes.logo}>
                <Logo
                  id="header-logo"
                  dark={(sticky || repo.dark) && !repo.black}
                  templateLabel={templateLabel}
                  onClick={() => GTMEvent("header", "click_link", "Home")}
                />
              </p>
            ) : (
              <div className={classes.logo}>
                <Logo
                  id="header-logo"
                  dark={(sticky || repo.dark) && !repo.black}
                  templateLabel={templateLabel}
                  onClick={() => GTMEvent("header", "click_link", "Home")}
                />
              </div>
            )}
            <div
              className={`${
                searchMobileIsOpen
                  ? classes.search_section_mobile_active
                  : classes.search_section_mobile
              }`}
            >
              <div className="container w-100 h-100">
                <div className="row h-100">
                  <div className="col-24 h-100">
                    <div className="px-2 h-100 d-flex justify-content-between align-items-center gap-4">
                      <Icon
                        className={classes.iconSearch}
                        name="search-white"
                      ></Icon>
                      <form className="form-search-mobile">
                        <input
                          className={classes.search_input}
                          id="search-input-mobile"
                          placeholder={t("search.label")}
                          value={text}
                          autoFocus
                          onChange={evt => {
                            setText(evt.target.value)
                            filterServerProducts(
                              evt.target.value,
                              props?.repo?.seo?.country
                            )
                          }}
                        ></input>
                      </form>
                      <Icon
                        className={classes.iconSearch}
                        name="close"
                        onClick={() => {
                          setSearchMobile(false)
                          setOpenSearch(false)
                          setText("")
                        }}
                      ></Icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Icon
                className={classes.iconSearchMobile}
                name={!sticky ? "search-white" : "search-black"}
                onClick={() => {
                  setSearchMobile(true)
                  document.getElementById("search-input-mobile").focus()
                  GTMEvent("Header", "click_button", "search open")
                }}
              ></Icon>
            </div>

            <div className={classes.desktopRightSection}>
              {myAreaLink && myAreaLink.href ? (
                <LoginButton
                  onClick={() => {
                    window.location.href = myAreaLink.href
                  }}
                  id="login-button"
                  {...myAreaLink}
                  color={`${
                    (sticky || repo.dark) && !repo.black ? "black" : "white"
                  }`}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Toolbar>
        </Hidden>
        {/* Search */}
        {openSearch && (
          <div
            className={clsx(
              classes.bgGrey,
              classes.overflowYScroll,
              `header_search calc-h-menu bg-grey01`
            )}
          >
            <div>
              {/* Cards */}
              <div className="mb-10">
                <div className="container px-2 pb-5">
                  <div className="row d-flex w-100 f-wrap">
                    {products.length === 0 ? (
                      <div
                        className={
                          "col-100 d-flex justify-content-center pt-64 fontPoppins"
                        }
                      >
                        {t("search.no_issue_search", { name: text })}
                      </div>
                    ) : products.length !== 1 ? (
                      products.map((product, index) => {
                        return (
                          <CardProductV2 type="half">
                            {{
                              ...product,
                            }}
                          </CardProductV2>
                        )
                      })
                    ) : (
                      products.map((product, index) => {
                        return (
                          <CardProductV2>
                            {{
                              ...product,
                            }}
                          </CardProductV2>
                        )
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AppBarUI>

      {/* MENU OFFSET MAIN */}
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        style={{ zIndex: 999999 }}
        PaperProps={{
          id: "menu-container",
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={8} align="left">
              <div className={classes.logo}>
                <Logo
                  dark={true}
                  onClick={() => GTMEvent("header", "click_link", "Home")}
                />
              </div>
            </Grid>
            <Grid item xs={4} align="right">
              <IconButton
                className={classes.closeButton}
                onClick={handleDrawerClose}
              >
                <Icon className={classes.closeIcon} name="close" />
              </IconButton>
            </Grid>

            {menuTitle ? (
              <Grid item xs={12}>
                <Text
                  className={classes.menuTitle}
                  variant="titleL"
                  weight="bold"
                >
                  {menuTitle}
                </Text>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </div>
        <List className={classes.list}>
          {(pages || []).map((page, index) =>
            page.categoryName ? (
              <Hidden smUp>
                <div>
                  <ul
                    key={index}
                    onClick={() => handleItemsMenuClick(index)}
                    className={classes.listItem}
                  >
                    <div className={classes.containerCategory}>
                      <span className={classes.categoryLink}>
                        {page.categoryName}
                      </span>
                      <Icon
                        className={classes.goIcon}
                        name="select_menu_mobile"
                        style={{
                          transform: `rotate(${
                            openItemsMenu.includes(index) ? 180 : 0
                          }deg)`,
                        }}
                      ></Icon>
                    </div>
                    {page.categoryItems.map(
                      (item, j) =>
                        openItemsMenu.includes(index) && (
                          <ListItem
                            id={
                              item.title
                                ? item.title.trim().replace(/ /g, "_")
                                : item.title
                            }
                            className={classes.listItem}
                            style={{ listStyleType: "none" }}
                            key={j}
                          >
                            <Box className={classes.itemWrapper}>
                              {item.chipText && (
                                <Chip
                                  className={classes.chip}
                                  label={item.chipText}
                                />
                              )}
                              <Link
                                className={classes.pageLink}
                                {...item.cta}
                                onClick={() => {
                                  GTMEvent(
                                    "header",
                                    "click_product",
                                    item.cta.title
                                  ) // TODO: distinguere tra product/page
                                }}
                              >
                                {item.cta.label}
                              </Link>
                            </Box>
                          </ListItem>
                        )
                    )}
                  </ul>
                </div>
              </Hidden>
            ) : (
              page.link && (
                <ListItem
                  id={
                    page.link.title
                      ? page.link.title.trim().replace(/ /g, "_")
                      : page.link.title
                  }
                  className={clsx("menu-item-class", classes.listItem)}
                  key={page.link.title}
                >
                  <Box className={classes.itemWrapper}>
                    {page.chipText && (
                      <Chip className={classes.chip} label={page.chipText} />
                    )}
                    <Link
                      className={classes.pageLink}
                      {...page.link}
                      onClick={() => {
                        GTMEvent("header", "click_product", page.link.title) // TODO: distinguere tra product/page
                      }}
                    >
                      <span>{page.link.title}</span>
                    </Link>
                  </Box>
                </ListItem>
              )
            )
          )}
        </List>
      </Drawer>

      {/* MENU OFFSET LOGIN */}
      <Drawer
        classes={{
          paper: classes.drawerUserArea,
        }}
        anchor={"right"}
        open={isUserAreaOpened}
        onClose={() => toogleUserArea(false)}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
          padding={3}
          className={classes.userMenuHeader}
        >
          <Text variant="bodyM" weight="bold" component="span">
            {t("login.title")}
          </Text>

          <Icon
            className={classes.closeIconUser}
            name="close"
            onClick={() => toogleUserArea(false)}
          ></Icon>
        </Box>
        <Box px={3}>
          <Box
            width={"100%"}
            py={2}
            textAlign="center"
            className={classes.loginText}
          >
            <Text variant="bodyM" weight="bold" component="span">
              {t("login.login")}
            </Text>
          </Box>
        </Box>

        <Icon name="search-white"></Icon>

        <Box p={3}>
          <Text variant="bodyM" weight="bold" component="span">
            {t("login.signIn")}
          </Text>
          <Box
            className={classes.socialBox}
            p={2}
            mt={2}
            onClick={() => {
              GTMEvent("login", "click_button", "login_google")
              window.gproxy.oauth2.google()
            }}
          >
            <Icon name="google" className={classes.iconDefault}></Icon>
            <Text variant="bodyS" component="span" color="#3b4154">
              {t("login.signWithGoogle")}
            </Text>
          </Box>
          <Box
            mt={2}
            className={classes.socialBox}
            p={2}
            onClick={() => {
              GTMEvent("login", "click_button", "login_facebook")
              window.gproxy.oauth2.facebook()
            }}
          >
            <Icon name="facebook" className={classes.iconDefault}></Icon>
            <Text variant="bodyS" component="span">
              {t("login.signWithFacebook")}
            </Text>
          </Box>
          <Box className={classes.divider}>
            <hr></hr>
            <span> {t("login.or")}</span>
          </Box>
          <Text variant="bodyM" weight="bold" component="span">
            {t("login.signWithEmail")}
          </Text>
          <Box mt={2}>
            <FormControl variant="outlined" className={classes.inputPass}>
              <InputLabel htmlFor="outlined-adornment-password">
                {t("login.email")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={"email"}
                value={values.email}
                onChange={handleChange("email")}
                className={classes.inputPass}
                label={t("login.email")}
              />
            </FormControl>
          </Box>

          <Box mt={2}>
            <FormControl variant="outlined" className={classes.inputPass}>
              <InputLabel htmlFor="outlined-adornment-password">
                {t("login.password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                className={classes.inputPass}
                endAdornment={
                  <InputAdornment position="end">
                    <Icon
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.showPassIcon}
                      name={
                        values.showPassword
                          ? "icon-password"
                          : "icon-password-disabled"
                      }
                    ></Icon>
                  </InputAdornment>
                }
                label={t("login.password")}
              />
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      setValues({ ...values, ["expired"]: e.target.checked })
                    }}
                    defaultChecked={values.expired}
                  />
                }
                label={t("login.keepMeLogin")}
              />
            </FormGroup>
          </Box>
          <Box mt={2}>
            <Button className={classes.buttonUserArea} onClick={onSubmitLogin}>
              {t("login.cta")}
              <Icon className={classes.arrowRight} name={"right_white"}></Icon>
            </Button>
            <Text color="error">{error}</Text>
          </Box>

          <Box mt={3}>
            <a
              href={`/${
                detectAlpha2Country() ? detectAlpha2Country() : "uk"
              }/myarea/p/reset`}
              className={classes.forgotPWDButton}
            >
              <Text variant="bodyXS" weight="bold" component="span">
                {t("login.forgotPassword")}
              </Text>
            </a>
          </Box>
        </Box>
      </Drawer>
    </EqualHeight>
  )
}

const defaultProps = {
  title: "",
  pages: [],
  myAreaLabel: "",
  myAreaHref: "",
}

HeaderModule.defaultProps = defaultProps

export default HeaderModule
