import React, { useState, useEffect } from 'react';

export const EqualHeight = ({ children, ...others }) => {
    const [PlaceHolder, setPlaceHolder] = useState('div');

    useEffect(() => {
        const { EqualHeight } = require('react-equal-height');
        setPlaceHolder(EqualHeight);
    }, []);

    return (<PlaceHolder {...others}>{children}</PlaceHolder>);
};

export const EqualHeightElement = ({ children, ...others }) => {
    const [PlaceHolder, setPlaceHolder] = useState('div');

    useEffect(() => {
        const { EqualHeightElement } = require('react-equal-height');
        setPlaceHolder(EqualHeightElement);
    }, []);

    return (<PlaceHolder {...others}>{children}</PlaceHolder>);
};